<nb-card class="container delete-confirmation-dialog modal">

  <div [ngClass]="sensitive ? 'modalSensitive' : null">
    <nb-card-header>
      <span class="title">
        <ng-container *ngIf="!sensitive; else sensitiveBlock">{{ title }}</ng-container>
        <ng-template #sensitiveBlock>{{ customTitle ? title : 'Exclusão Sensível'}}</ng-template>
      </span>

      <button type="button" class="close-btn" nbButton ghost #btnNoFocus data-id="button_close" (click)="close(false)">
        <nb-icon icon="close-outline"></nb-icon>
      </button>
    </nb-card-header>
    <nb-card-body>
      <ng-container *ngIf="!sensitive; else sensitiveCardBody">
        {{ text }}
      </ng-container>
      <ng-template #sensitiveCardBody>
        <label>{{ customText || 'Você está prestes a excluir um registro sensível, confirme o texto abaixo para realizar a exclusão.' }}</label>
        <strong *ngIf="showTitle">{{ title }}</strong>
        <form [formGroup]="form" novalidate>
          <div class="form">
            <div class="input-group">
              <label for="delete">Digite "{{ customValidator }}" no campo abaixo para prosseguir:</label>
              <input nbInput fullWidth type="text" data-id="input_delete" formControlName="delete" id="delete"
                [placeholder]="'Digite ' + customValidator" autocomplete="off">
              <div class="fixed-placeholder">
                <small class="text-danger" *ngIf="form.get('delete').hasError('required') && form.get('delete').touched">
                  Campo obrigatório.
                </small>
              </div>
            </div>
          </div>
        </form>
      </ng-template>
    </nb-card-body>

    <nb-card-footer class="btn--actions">
      <button nbButton status="basic" data-id="button_cancel" (click)="close(false)">
        Cancelar
      </button>

      <button type="button" nbButton [status]="buttonStatus" data-id="button_submit" (click)="close(true)" [disabled]="sensitive ? form.invalid : false">
        Confirmar
      </button>
    </nb-card-footer>
  </div>
</nb-card>