import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { IProvider } from '../../models/provider';
import { CommonModule } from '@angular/common';
import { CustomNebularThemeModule } from 'src/app/shared/customs/custom-nebular-theme.module';
import { DeleteConfirmationDialogForm } from 'src/app/shared/models/delete-confirmation';

@Component({
  selector: 'app-delete-confirmation-dialog',
  templateUrl: './delete-confirmation-dialog.component.html',
  styleUrls: ['./delete-confirmation-dialog.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    CustomNebularThemeModule
  ]
})
export class DeleteConfirmationDialogComponent implements OnInit {

  title: string;
  text: string;
  sensitive: boolean = false;
  showTitle = true;
  customText: string = null;
  customTitle: boolean = false;
  customValidator: string = 'excluir definitivamente';
  form: FormGroup<DeleteConfirmationDialogForm>;
  selectedProvider: IProvider;
  buttonStatus = 'danger';
  @ViewChild('btnNoFocus') btnNoFocus: any;

  constructor(
    private dialogRef: NbDialogRef<DeleteConfirmationDialogComponent>,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    this.form = this.fb.group({
      delete: new FormControl(null, [Validators.required, Validators.pattern(this.customValidator)]),
    });
  }

  close(isDelete: boolean): void {
    this.dialogRef.close(isDelete);
  }
}