import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { User } from "../models/user";

const canActivate: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const user: User = JSON.parse(sessionStorage.getItem('currentUser'));
  const router = inject(Router)
  if (user.documentPending) return router.createUrlTree(['terms/pending']);
  return true;
};

export const PendingLgpdGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => canActivate(route, state);
