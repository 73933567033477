export default class GetUserInfo {

  static execute(): string {
    let curDate = new Date();
    let dateStr = `${curDate.toLocaleDateString('pt-BR')} - ${curDate.toLocaleTimeString('en-US')}`;
    let unknown = '-';
    let nAgt = navigator.userAgent;
    let os = unknown;
    let clientStrings = [
      { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
      { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
      { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
      { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
      { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
      { s: 'Windows Vista', r: /Windows NT 6.0/ },
      { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
      { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
      { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
      { s: 'Windows 98', r: /(Windows 98|Win98)/ },
      { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
      { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
      { s: 'Windows CE', r: /Windows CE/ },
      { s: 'Windows 3.11', r: /Win16/ },
      { s: 'Android', r: /Android/ },
      { s: 'Open BSD', r: /OpenBSD/ },
      { s: 'Sun OS', r: /SunOS/ },
      { s: 'Chrome OS', r: /CrOS/ },
      { s: 'Linux', r: /(Linux|X11(?!.*CrOS))/ },
      { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
      { s: 'Mac OS X', r: /Mac OS X/ },
      { s: 'Mac OS', r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
      { s: 'QNX', r: /QNX/ },
      { s: 'UNIX', r: /UNIX/ },
      { s: 'BeOS', r: /BeOS/ },
      { s: 'OS/2', r: /OS\/2/ },
      {
        s: 'Search Bot',
        r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
      },
    ];

    for (let id in clientStrings) {
      let cs = clientStrings[id];
      if (cs.r.test(nAgt)) {
        os = cs.s;
        break;
      }
    }

    if (/Windows/.test(os)) {
      os = 'Windows';
    }

    let tem,
      M =
        nAgt.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];

    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(nAgt) || [];
      return `IE (${tem[1]}) @ ${os}, ${dateStr}`;
    }

    if (M[1] === 'Chrome') {
      tem = nAgt.match(/\bOPR\/(\d+)/);

      if (tem != null) {
        return `Opera (${tem[1]}) @ ${os}, ${dateStr}`;
      }
    }

    M = [M[1], M[2]];
    if ((tem = nAgt.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }

    return `${M[0]} (${M[1]}) @ ${os}, ${dateStr}`;
  }

  static getBrowser(): 'chrome' | 'firefox' | 'edge' {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf("Edge") !== -1 || userAgent.indexOf("Edg") !== -1) {
      return "edge";
    }
    if (userAgent.indexOf("Firefox") !== -1) {
      return "firefox";
    }
    if (userAgent.indexOf("Safari") !== -1 && userAgent.indexOf('Macintosh') !== -1) {
      return "chrome";
    }
    if (userAgent.indexOf("Chrome") !== -1) {
      return "chrome";
    }
    if (userAgent.indexOf("Opera") !== -1 || userAgent.indexOf("OPR") !== -1) {
      return "chrome";
    }
    if (userAgent.indexOf("Trident") !== -1) {
      return "chrome";
    }
  }
}