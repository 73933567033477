import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbButtonModule, NbCardModule, NbStepperComponent, NbStepperModule } from '@nebular/theme';
import GetUserInfo from '../../utils/get-user-info';
import { ToastService } from 'ngx-french-toast';

@Component({
  selector: 'app-notification-reset-walkthrough',
  standalone: true,
  imports: [CommonModule, NbCardModule, NbStepperModule, NbButtonModule],
  templateUrl: './notification-reset-walkthrough.component.html',
  styleUrls: ['./notification-reset-walkthrough.component.scss']
})
export class NotificationResetWalkthroughComponent implements OnInit {
  @ViewChild('stepper') stepper: NbStepperComponent;
  userBrowser: "chrome" | "firefox" | "edge";
  userIsLogged: boolean;

  constructor(private toastService: ToastService) {}

  ngOnInit(): void {
    this.getUserBrowser();
  }

  getUserBrowser(): void {
    this.userBrowser = GetUserInfo.getBrowser();
  }

  next(step?: string): void {
    if (step === 'redefine') {
      if (Notification.permission === 'default') {
        this.stepper.next();
        return;
      }
      this.toastService.warning({
        title: 'Redefinição de Notificações',
        content: 'Para prosseguir, é necessário redefinir suas preferências de notificações. Por favor, siga o tutorial.'
      })
      return;
    }
    if (step === 'logout') {
      const user = JSON.parse(sessionStorage.getItem('currentUser'));
      if (!user) {
        this.stepper.next();
        return;
      }
      this.toastService.warning({
        title: 'Redefinição de Notificações',
        content: 'Para prosseguir, é necessário finalizar sua sessão. Por favor, siga o tutorial.'
      })
      return;
    }
    if (step === 'refresh') {
      location.reload();
      return;
    }
    this.stepper.next();
  }

  prev(): void {
    this.stepper.previous();
  }
}
