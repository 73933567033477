import { Injectable } from '@angular/core';

import { NbMenuItem } from '@nebular/theme';
import { BehaviorSubject } from 'rxjs';
import { Authorization } from '../models/authorization';
import { Hierarchy } from '../models/hierarchy';
import { GetHierarchy } from '../utils/get-hierarchy';
import { User } from '../models/user';
import { ProfileType } from '../enums/profile-type';
import { ProviderAuths } from '../models/provider-auths';

@Injectable({
  providedIn: 'root'
})
export class ProfileAuthorizationsService {

  hierarchy: Hierarchy;
  menu: BehaviorSubject<NbMenuItem[]> = new BehaviorSubject<NbMenuItem[]>([]);

  constructor() { }

  setAuth(authorizations: Authorization[]): void {
    sessionStorage.setItem('authorizations', JSON.stringify(authorizations));
  }

  private getAuths(): Authorization[] {
    return JSON.parse(sessionStorage.getItem('authorizations'));
  }

  checkUrl(url: string): boolean {
    const authorizations = this.getAuths();
    const authorization = authorizations.find(authorization => {
      if (authorization.link === url) {
        return true;
      }

      return authorization.children.find(auth => {
        if (auth.link === url) {
          return true;
        }

        return auth.children.find(grandchild => {
          if (grandchild.link === url) {
            return true;
          }
        })
      });
    });

    return authorization ? true : false;
  }

  getAuth(url: string): Authorization {
    const authorizations = this.getAuths();

    const serializeAuth = this.serializeAuths(authorizations);

    let auth;

    serializeAuth.find(authorization => {
      const authFound = authorization.children.find(auth => auth.link === url);

      if (authFound) {
        auth = authFound;
      }
    });

    return auth;
  }

  getSidebarMenu(): void {
    const authorizations = this.getAuths();
    const serializedAuths = this.serializeAuths(authorizations);
    serializedAuths.map(auth => {
      auth.children = auth.children.filter(a => a.show_menu);
      if (auth.children.length === 0) delete auth.children;
    })
    this.createMenuItems(serializedAuths);
    this.menu.next(serializedAuths);
  }

  createMenuItems(auths: Authorization[]): void {
    const index = auths.findIndex(e => e.title === 'Recursos');
    const user = this.getUserData();
    const isAdmin = user.profileType === ProfileType.Company;
    const isProvider = user.profileType === ProfileType.Provider;
    const isCustomer = user.profileType === ProfileType.Customer;
    const isResale = user.profileType === ProfileType.Resale;
    if (isProvider || isResale) {
      const authorizations: ProviderAuths = JSON.parse(sessionStorage.getItem('providerAuths'));
      if (authorizations?.community) {
        this.createResourceCommunity(auths[index]);
      }
    }
    if (isAdmin) {
      this.createResourceCommunity(auths[index]);
    }
    if (isCustomer) {
      const communityAccess: boolean = JSON.parse(sessionStorage.getItem('communityAccess'));
      if (communityAccess) {
        const dashboardIndex = auths.findIndex(e => e.title === 'Dashboard');
        this.createDashboardCommunity(auths[dashboardIndex]);
      }
    }
  }

  private createResourceCommunity(auth: Authorization): void {
    auth.children.push({
      title: 'Comunidades',
      link: '/resource/communities'
    })
  }

  private createDashboardCommunity(auth: Authorization): void {
    auth.children.push({
      title: 'Comunidades',
      link: '/dashboard/communities'
    })
  }

  private serializeAuths(authorizations: Authorization[]): Authorization[] {
    this.hierarchy = GetHierarchy.execute();
    authorizations.map(auths => {
      auths.children.map(auth => {
        if (auth.title === 'Revendas') auth.title = this.hierarchy.level_one_plural;
        if (auth.title === 'Clientes') auth.title = this.hierarchy.level_two_plural;
        if (auth.children.length > 0) {
          auth.data = auth.children;
        }

        auth.children.forEach(e => {
          delete e.children;
        })

        auth.children = auth.children.filter(e => e.show_menu === true);
        if (auth.children.length === 0) delete auth.children;
      });
    })
    authorizations = authorizations.filter(auths => auths.show_menu);
    authorizations = this.sortMenu(authorizations);
    authorizations.forEach(e => {
      if (e.children) {
        e.children = this.sortMenu(e.children);
      }
    })
    return authorizations;
  }

  getUserData(): User {
    const user: User = JSON.parse(sessionStorage.getItem('currentUser'));
    return user;
  }

  private sortMenu(array: Authorization[]): Authorization[] {
    array.sort((a,b) => {
      if (a.order < b.order)
      return -1;
      if (a.order > b.order)
        return 1;
      return 0;
    });
    return array;
  }

  getTopMenu(): NbMenuItem[] {
    const topMenu: NbMenuItem[] = [];

    const authorizations = this.getAuths();

    authorizations.map(authorization => {
      if (authorization.link === '/config/my-account') {
        topMenu.push({
          title: "Minha Conta", link: '/config/my-account'
        });
      }

      if (authorization.children.length > 0) {
        authorization.children.map(auth => {
          if (auth.link === '/config/my-account') {
            topMenu.push({
              title: "Minha Conta", link: '/config/my-account'
            });
          }
        });
      }
    });

    const user = JSON.parse(sessionStorage.getItem('currentUser'));
    if (user.profileName === 'Administrador Seventh') {
      topMenu.push({
        title: 'Autorizações', link: '/config/authorizations'
      })
    }

    topMenu.push(
      { title: 'Meus Consentimentos', link: '/terms/accepted' },
      { title: 'Sobre' },
      { title: 'Sair' }
    );

    return topMenu;
  }

  getLinkForRedirect(): string {
    let authorizations = this.getAuths();
    authorizations = this.sortMenu(authorizations);
    authorizations.forEach(auth => {
      if (auth.children) {
        auth.children = this.sortMenu(auth.children);
        auth.children.forEach(childAuth => {
          if (childAuth.children) {
            childAuth.children = this.sortMenu(childAuth.children);
          }
        })
      }
    })
    return authorizations[0].children[0].link;
  }
}
