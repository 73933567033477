import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SocketService } from 'src/app/shared/services/socket.service';
import { ToastService } from 'ngx-french-toast';

@Injectable({
  providedIn: 'root'
})
export class SignOutService {

  constructor(
    private router: Router,
    private socketService: SocketService,
    private toastService: ToastService
  ) { }

  signOut(isTokenExpired = false): void {

    Object.keys(sessionStorage).forEach(item => {
      if (item !== 'whiteLabel') {
        sessionStorage.removeItem(item);
      }
    })
    this.router.navigate(['/authentication/login']).then(() => {
      setTimeout(() => {
        if (isTokenExpired) {
          this.toastService.danger({
            title: 'Sessão Expirada',
            content: 'Por favor, faça o login novamente.',
            duration: 10000
          });
        }
      }, 0);
    });
    this.socketService.close();
  }
}
