import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ProfileType } from '../../enums/profile-type';
import { User } from '../../models/user';
import { AboutData } from '../../services/about.service';
import { CommonModule } from '@angular/common';
import { NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { CustomNebularThemeModule } from 'src/app/shared/customs/custom-nebular-theme.module';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NgxMaskPipe,
    CustomNebularThemeModule
  ],
  providers: [
    provideNgxMask()
  ]
})
export class AboutComponent implements OnInit {
  aboutData: AboutData;
  user: User
  isAdmin: boolean;
  isProvider: boolean;

  constructor(
    private dialogRef: NbDialogRef<AboutComponent>
  ) { }

  ngOnInit(): void {
   this.user = JSON.parse(sessionStorage.getItem('currentUser'));
   this.isAdmin = this.user.profileType === ProfileType.Company;
   this.isProvider = this.user.profileType === ProfileType.Provider;
  }

  close(): void {
    this.dialogRef.close();
  }

}
