import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.scss']
})
export class BlankComponent implements OnInit {
  customClass: string;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.checkComponent();
  }

  checkComponent() {
    if (this.router.url.includes('/authentication/share/')) {
      this.customClass = 'no-min-height';
    }
  }

}
