import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SimultaneousSessionService {

  hasSimultaneousSession: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  message: BehaviorSubject<{title: string, text: string}> = new BehaviorSubject<{title: string, text: string}>(null);

  constructor() { }
}
