<nb-card class="container change-user-photo modal" [nbSpinner]="loading" [ngClass]="theme ? theme : ''">
  <nb-card-header>
    <span class="title">
      Adicionar ou Alterar Foto
    </span>
    <button type="button" class="close-btn" nbButton ghost #btnNoFocus data-id="button_close" (click)="close()">
      <nb-icon icon="close-outline"></nb-icon>
    </button>
  </nb-card-header>
  <nb-card-body>
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true"
      [aspectRatio]="1/1"
      format="webp"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded($event)"
      (cropperReady)="onCropperReady($event)"
      [style.display]="showCropper ? null : 'none'"
      alignImage="center"
      [cropperMinWidth]="200"
      [canvasRotation]="0"
      output="base64">
    </image-cropper>
    <div class="image-cropper" *ngIf="showCropper">
      <button type="button" nbButton status="basic" (click)="cancelCrop()">
        Cancelar
      </button>
      <button type="button" nbButton status="primary" (click)="addCroppedImage()" [disabled]="loading">
        Confirmar
      </button>
    </div>
    <div [style.display]="!showCropper ? null : 'none'" class="user-photo__container user-photo__import">
      <div class="input_user-photo_import__inner" [ngClass]="theme">
        <input nbInput fullWidth type="file" [accept]="acceptedFormats" (change)="fileChangeEvent($event)"
          data-id="input_user-photo_import" [disabled]="loading" [(ngModel)]="file">
        <div class="circle" *ngIf="!currentImage">
          <svg viewBox="0 0 24 24" class="icon">
            <use xlink:href="../../../../assets/svg/sprite.svg#icon-photo"></use>
          </svg>
          <svg class="shadow" width="55" height="72">
            <use xlink:href="../../../../assets/svg/sprite.svg#shadow"></use>
          </svg>
        </div>
        <div class="circle-photo" *ngIf="currentImage">
          <img [src]="currentImage">
        </div>
        <div class="input_user-photo_import__inner--bottom">
          <span>Clique ou arraste a imagem</span>
          <button nbButton status="primary">{{ currentImage ? 'Alterar' : 'Adicionar'}}</button>
        </div>
      </div>
      <button class="remove-photo" (click)="deleteImage()" *ngIf="!showCropper && currentImage" [ngClass]="theme">
        <svg width="9" height="10">
          <use xlink:href="../../../../assets/svg/sprite.svg#icon-bin"></use>
        </svg>
        Remover imagem
      </button>
    </div>
    <div class="buttons" *ngIf="!showCropper">
      <button nbButton status="basic" (click)="close()">Cancelar</button>
      <button nbButton status="primary" (click)="save()" [disabled]="loading || disableButton">Salvar</button>
    </div>
  </nb-card-body>
</nb-card>