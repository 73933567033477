import { IFirebaseConfigs, IFirebaseServerConfig } from './../models/firebase-config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Notification } from '../models/notification';
import { NotificationParams } from '../models/notification-params';
import { IPushNotificationConfig } from '../models/push-notification-subscription';
import { IResponseMetadata } from '../models/response-metadata';
import { ICreateAnnouncementInput } from './announcement.service';
import { INotificationType } from '../models/notification-type';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private mainApi = `${environment.api}/main`;
  private notificationsApi = `${environment.api}/notifications`;
  hasNotification = new BehaviorSubject<boolean>(false);
  private displayResetWalkthrough = new Subject<boolean>();
  constructor(
    private http: HttpClient
  ) { }

  getAll(data: NotificationInput, searchAll: boolean = false, unreadOnly: boolean = false): Observable<NotificationOutput> {
    const url = `${this.notificationsApi}/v1/list/${searchAll}`;
    const params = {
      notificationTypes: JSON.stringify(data?.notificationType ? [data.notificationType] : []),
      startDate: data.startDate,
      endDate: data.endDate,
      _id_provider: data._id_provider,
      _id_server: data._id_server,
      _id_subscriber: data._id_subscriber,
      _id_camera: data._id_camera,
      skip: data.skip,
      limit: data.limit,
      unreadOnly: unreadOnly
    };
    const { headers } = this.getHeaders();
    return this.http.get<NotificationOutput>(url, { headers, params });
  }

  createInstabilityNotifications(data: ICreateAnnouncementInput): Observable<any> {
    const url = `${this.notificationsApi}/v1/instability`;
    return this.http.post(url, data, this.getHeaders());
  }

  hasNew(): Observable<boolean> {
    const url = `${this.notificationsApi}/v1/has-new`;
    return this.http.get<boolean>(url, this.getHeaders());
  }

  getNotificationParamsByProviderId(providerId: string): Observable<NotificationParams> {
    const url = `${this.mainApi}/v1/notification-params/${providerId}`;
    return this.http.get<NotificationParams>(url, this.getHeaders());
  }

  updateNotificationParams(notificationParams: NotificationParams): Observable<NotificationParams> {
    const url = `${this.mainApi}/v1/notification-params/${notificationParams._id}`;
    return this.http.put<NotificationParams>(url, notificationParams, this.getHeaders());
  }

  createPushNotificationSubscription(_id_user: string, token: string): Observable<void> {
    const url = `${this.notificationsApi}/v1/push-notification`;
    const subscriptionTyped: IPushNotificationConfig = {
      _id_user,
      device: {
        key: token,
        type: 'web'
      }
    };
    return this.http.post<void>(url, subscriptionTyped, this.getHeaders());
  }

  getFirebaseConfig(_id_provider: string): Observable<IFirebaseConfigs> {
    const url = `${this.notificationsApi}/v1/push-notification/firebase-configs/${_id_provider}`;
    return this.http.get<IFirebaseConfigs>(url, this.getHeaders());
  }

  updateFirebaseConfig(data: IFirebaseConfigs): Observable<void> {
    const url = `${this.notificationsApi}/v1/push-notification/firebase-configs`;
    return this.http.post<void>(url, data, this.getHeaders());
  }

  getNotificationTypes(): Observable<INotificationType[]> {
    const url = `${this.notificationsApi}/v1/types`;
    return this.http.get<INotificationType[]>(url, this.getHeaders());
  }

  getResetWalkthrough(): Observable<boolean> {
    return this.displayResetWalkthrough.asObservable();
  }

  setDisplayWalkthrough(display: boolean): void {
    this.displayResetWalkthrough.next(display);
  }

  checkOldUserNotificationPermission(userId: string): Observable<boolean> {
    const url = `${this.notificationsApi}/v1/push-notification/check-user/${userId}`;
    return this.http.get<boolean>(url, this.getHeaders());
  }

  private getHeaders(): { headers: HttpHeaders } {
    const tkjwt = sessionStorage.getItem('tkjwt');

    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${tkjwt}`,
        'x-access-token': tkjwt
      })
    };
  }

  getNotification(id: string): Observable<Notification> {
    const url = `${this.notificationsApi}/v1/${id}`;
    return this.http.get<Notification>(url, this.getHeaders())
  }

  readNotification(id: string): Observable<string> {
    const url = `${this.notificationsApi}/v1/${id}/read`;
    return this.http.put<string>(url, {}, this.getHeaders())
  }

  markAllAsRead() {
    const url = `${this.notificationsApi}/v1/markAllAsRead`;
    return this.http.put(url, {}, this.getHeaders());
  }

  respondCameraRequest(notificationId: string, approved: boolean): Observable<void> {
    const url = `${this.notificationsApi}/v1/${notificationId}/camera-request`;
    const params = { approved };
    const { headers } = this.getHeaders();
    return this.http.patch<void>(url, {}, { headers, params });
  }
}

export interface NotificationInput {
  notificationType?: string;
  startDate?: string;
  endDate?: string;
  _id_provider?: string;
  _id_server?: string;
  _id_subscriber?: string;
  _id_camera?: string;
  skip: number;
  limit: number;
}

export interface GetNotificationInputForComponents extends Omit<NotificationInput, 'skip' | 'limit'> {
  unreadOnly: boolean;
}

export interface NotificationOutput {
  metadata: IResponseMetadata;
  results: Notification[];
}