import { Component, OnDestroy, OnInit } from '@angular/core';

import { NbMenuItem, NbThemeService } from '@nebular/theme';
import { Subject, takeUntil } from 'rxjs';
import { User } from 'src/app/shared/models/user';
import { WhiteLabel } from 'src/app/shared/models/white-label';
import { HideMenuByRouteService } from 'src/app/shared/services/hide-menu-by-route.service';
import { ProfileAuthorizationsService } from 'src/app/shared/services/profile-authorizations.service';
import { RecalculateTableService } from 'src/app/shared/services/recalculate-table.service';

@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss'],
})
export class FullComponent implements OnInit, OnDestroy {

  menu: NbMenuItem[] = [];
  whiteLabel: WhiteLabel;
  currentUser: User;
  headerBackgroundColor: string;
  hideMenu = false;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private nebularThemeService: NbThemeService,
    private profileAuthorizationsService: ProfileAuthorizationsService,
    private hideMenuByRouteService: HideMenuByRouteService,
    private recalculateTable: RecalculateTableService,
  ) { }

  ngOnInit(): void {
    this.isHideMenu();
    this.whiteLabel = JSON.parse(sessionStorage.getItem('whiteLabel'));
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.listenForMenuChanges();
    this.getHeaderBackgroundColor();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  listenForMenuChanges(): void {
    this.profileAuthorizationsService.getSidebarMenu();
    this.profileAuthorizationsService.menu.pipe(takeUntil(this.destroy$)).subscribe({
      next: (menu => {
        this.menu = menu;
      })
    })
  }

  isHideMenu(): void {
    this.hideMenuByRouteService
      .getHideMenu()
      .pipe(takeUntil(this.destroy$))
      .subscribe(status => {
        this.hideMenu = status;
      });
  }

  getHeaderBackgroundColor(): void {
    if (this.nebularThemeService.currentTheme === "cosmic") {
      this.headerBackgroundColor = "#1e1e1e";
      return;
    }
    this.headerBackgroundColor = "transparent";
  }

  handleMenuState(state: string): void {
    sessionStorage.setItem('menu-state', state);
  }

  recalculateWidth(): void {
    this.recalculateTable.recalculateTrigger.next(true);
  }
}
