import { NgModule } from '@angular/core';
import { NbEvaIconsModule } from '@nebular/eva-icons';

import {
  NbLayoutModule,
  NbSidebarModule,
  NbMenuModule,
  NbCardModule,
  NbIconModule,
  NbFormFieldModule,
  NbInputModule,
  NbSpinnerModule,
  NbButtonModule,
  NbActionsModule,
  NbButtonGroupModule,
  NbUserModule,
  NbContextMenuModule,
  NbSelectModule,
  NbPopoverModule,
  NbDialogModule,
  NbDatepickerModule,
  NbGlobalLogicalPosition,
  NbTooltipModule,
  NbAutocompleteModule,
  NbStepperModule,
  NbListModule,
  NbAccordionModule,
  NbCheckboxModule,
  NbRadioModule,
  NbWindowModule,
  NbTimepickerModule,
  NbTagModule,
  NbToggleModule
} from '@nebular/theme';

import {
  NbDateFnsDateModule
} from '@nebular/date-fns'

@NgModule({
  declarations: [],
  imports: [
    NbLayoutModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbCardModule,
    NbIconModule,
    NbEvaIconsModule,
    NbFormFieldModule,
    NbInputModule,
    NbSpinnerModule,
    NbButtonModule,
    NbActionsModule,
    NbButtonGroupModule,
    NbUserModule,
    NbContextMenuModule,
    NbSelectModule,
    NbPopoverModule,
    NbDialogModule.forRoot({
      autoFocus: false,
      closeOnBackdropClick: false
    }),
    NbDatepickerModule.forRoot(),
    NbTimepickerModule.forRoot(),
    NbTooltipModule,
    NbAutocompleteModule,
    NbStepperModule,
    NbListModule,
    NbAccordionModule,
    NbCheckboxModule,
    NbRadioModule,
    NbWindowModule.forRoot(),
    NbDateFnsDateModule,
    NbTagModule
  ],
  exports: [
    NbLayoutModule,
    NbSidebarModule,
    NbMenuModule,
    NbCardModule,
    NbIconModule,
    NbEvaIconsModule,
    NbFormFieldModule,
    NbInputModule,
    NbSpinnerModule,
    NbButtonModule,
    NbActionsModule,
    NbButtonGroupModule,
    NbUserModule,
    NbContextMenuModule,
    NbSelectModule,
    NbPopoverModule,
    NbDialogModule,
    NbDatepickerModule,
    NbTooltipModule,
    NbAutocompleteModule,
    NbStepperModule,
    NbListModule,
    NbAccordionModule,
    NbCheckboxModule,
    NbRadioModule,
    NbWindowModule,
    NbDateFnsDateModule,
    NbTagModule,
    NbToggleModule,
    NbSpinnerModule
  ]
})
export class CustomNebularThemeModule { }
