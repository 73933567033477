import { ToastService } from 'ngx-french-toast';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { WhiteLabel } from './shared/models/white-label';
import { NbDialogService, NbIconLibraries } from '@nebular/theme';
import { Subject, takeUntil } from 'rxjs';
import { SimultaneousSessionService } from './shared/services/simultaneous-session.service';
import { SocketService } from './shared/services/socket.service';
import { User } from './shared/models/user';
import { ProfileType } from './shared/enums/profile-type';
import { SwUpdate } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { DeleteConfirmationDialogComponent } from './shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { NotificationService } from './shared/services/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  hasSimultaneousSession: boolean = false;
  displayResetWalkthrough: boolean = false;

  private readonly destroy$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private iconLibraries: NbIconLibraries,
    private simultaneousSessionService: SimultaneousSessionService,
    private socketService: SocketService,
    private swUpdate: SwUpdate,
    private toastService: ToastService,
    private dialogService: NbDialogService,
    private afMessaging: AngularFireMessaging,
    private notificationService: NotificationService
  ) {
    this.iconLibraries.registerSvgPack('grid', {
      'grid2': `<svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1_11_6" fill="white"><rect width="9.14815" height="19" rx="1"/></mask>
        <rect width="9.14815" height="19" rx="1" stroke="currentColor" stroke-width="3" mask="url(#path-1-inside-1_11_6)"/>

        <mask id="path-2-inside-2_11_6" fill="white"><rect x="10.9074" width="9.14815" height="19" rx="1"/></mask>
        <rect x="10.9074" width="9.14815" height="19" rx="1" stroke="currentColor" stroke-width="3" mask="url(#path-2-inside-2_11_6)"/>
      </svg>`,
    });
  }

  ngOnInit(): void {
    const user: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.checkWhiteLabel();
    this.checkSimultaneousSessions();
    this.socketService.login();
    this.socketService.notification();
    if (user?.profileType === ProfileType.Customer) this.socketService.blockUser();
    this.serviceWorkerUpdate();
    this.listen();
    this.getNotificationResetWalkthrough();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkWhiteLabel(): void {
    const whiteLabel: WhiteLabel = JSON.parse(sessionStorage.getItem('whiteLabel'));

    if (whiteLabel) {
      this.document.title = whiteLabel.name;

      const link: any = this.document.querySelector(`link[rel*='icon']`)
        || this.document.createElement('link');

      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = whiteLabel.favicon;

      this.document.getElementsByTagName('head')[0].appendChild(link);
    }
  }

  checkSimultaneousSessions() {
    this.simultaneousSessionService.hasSimultaneousSession.pipe(takeUntil(this.destroy$)).subscribe(
      (hasSimultaneousSession) => {
        this.hasSimultaneousSession = hasSimultaneousSession;
      }
    );
  }

  listen(): void {
    const user: User = JSON.parse(sessionStorage.getItem('currentUser'));
    const shouldListenForNotifications = Notification.permission === 'granted' && user && user.profileType !== ProfileType.Company;
    if (shouldListenForNotifications) {
      this.afMessaging.messages.subscribe((message) => {
        new Notification(message.data.title, { body: message.data.body, image: message.data?.imageUrl, icon: message.data?.imageUrl });
      });
    }
  }

  getNotificationResetWalkthrough(): void {
    this.notificationService.getResetWalkthrough()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (displayResetWalkthrough) => {
          this.displayResetWalkthrough = displayResetWalkthrough;
        }
      })
  }

  serviceWorkerUpdate(): void {
    if (this.swUpdate.isEnabled && environment.sw) {
      this.swUpdate.versionUpdates.pipe(takeUntil(this.destroy$)).subscribe({
        next: (res) => {
          if (res.type === 'VERSION_READY') {
            const dialog = this.dialogService.open(DeleteConfirmationDialogComponent, {
              context: {
                title: 'Nova Versão do Sistema Disponível',
                text: 'Uma nova versão do sistema está disponível. Deseja utilizá-la agora?',
                buttonStatus: 'primary'
              },
            });
            dialog.onClose
            .pipe(takeUntil(this.destroy$))
            .subscribe(confirmation => {
              if (confirmation) {
                this.swUpdate.activateUpdate().then(() => document.location.reload());
              } else {
                this.toastService.info({
                  title: 'Nova Versão do Sistema Disponível',
                  content: 'Recarregue seu navegador para utilizar a nova versão do sistema.',
                  duration: 10000
                })
              }
            });
          }
        }
      })
    }
  }
}