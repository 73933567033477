import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AboutService {

  private api = `${environment.api}/main/v1`;

  constructor(
    private http: HttpClient
  ) { }

  getAboutData(): Observable<AboutData> {
    const url = `${this.api}/home/aboutInfo`;

    return this.http.get<AboutData>(url, this.getHeaders());
  }

  private getHeaders(): { headers: HttpHeaders } {
    const tkjwt = sessionStorage.getItem('tkjwt');

    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${tkjwt}`,
        'x-access-token': tkjwt
      })
    }
  }
}

export interface AboutData {
  systemVersion: string;
  documentationURL: string;
  changelogsURL: string;
  supportEmail: string;
  supportPhone: string;
  supportWhatsapp: string;
}