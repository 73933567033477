<nb-card class="reset-walkthrough">
  <nb-card-body>
    <nb-stepper #stepper>
      <nb-step>
        <h6>Atenção</h6>
        <p>As suas configurações atuais de Notificações Push vão parar de funcionar. Para que elas sigam funcionando, é necessário seguir estes passos.</p>
        <p>Vamos lá?</p>
        <div class="button">
          <button nbButton status="primary" (click)="next()">Seguir</button>
        </div>
      </nb-step>
      <nb-step>
        <img [src]="'../../../../assets/img/gifs/redefine_' + userBrowser + '.gif'">
        <p>Redefina as Permissões no seu browser.</p>
        <div class="button">
          <button nbButton (click)="prev()">Voltar</button>
          <button nbButton status="primary" (click)="next('redefine')">Seguir</button>
        </div>
      </nb-step>
      <nb-step>
        <img [src]="'../../../../assets/img/gifs/logoff.gif'">
        <p>Finalize sua sessão.</p>
        <div class="button">
          <button nbButton (click)="prev()">Voltar</button>
          <button nbButton status="primary" (click)="next('logout')">Seguir</button>
        </div>
      </nb-step>
      <nb-step>
        <p>Prontinho! Agora é só clicar abaixo para recarregar sua tela.</p>
        <div class="button">
          <button nbButton (click)="prev()">Voltar</button>
          <button nbButton status="primary" (click)="next('refresh')">Recarregar</button>
        </div>
      </nb-step>
    </nb-stepper>
  </nb-card-body>
</nb-card>