
<div class="about">
  <div class="about__logo">
    <img src="../../../../assets/img/dguard-cloud_logo.png" alt="Dguard Cloud">
  </div>
  <div class="about__item">
    <div class="about__item--title">
      Sobre o aplicativo D-Guard Cloud
    </div>
    <div class="about__item--version">
      Version {{ aboutData?.systemVersion }}
    </div>
  </div>

  <div class="about__item">
    <div class="about__item--title">
    Contato Suporte
    </div>
    <div class="about__item--contact" [ngClass]="{
      'three-col': isProvider
    }">
      <div class="contact" *ngIf="isProvider">
        <a target="_blank" [href]="'https://wa.me/'+ aboutData?.supportWhatsapp + '?text=Ol%C3%A1'">
          <svg>
            <use xlink:href="../../../../assets/svg/sprite.svg#icon-whatsapp"></use>
          </svg>{{ aboutData?.supportWhatsapp | mask: '+00 (00) 0000-0000' }}
        </a>
      </div>
      <div class="contact">
        <a target="_blank" [href]="'tel:' + aboutData?.supportPhone">
          <nb-icon icon="phone-outline"></nb-icon><span>{{ aboutData?.supportPhone | mask: '(00) 0000-0000' }}</span>
        </a>
      </div>
      <div class="contact">
        <a target="_blank" [href]="'mailto:' + aboutData?.supportEmail">
          <nb-icon icon="email-outline"></nb-icon>{{ aboutData?.supportEmail }}
        </a>
      </div>
    </div>
  </div>

  <div class="about__item">
    <div class="about__item--title">
      Documentação
    </div>
    <div class="links" [ngClass]="isAdmin || isProvider ? 'two-col': ''" >
      <div class="link">
        <a target="_blank" [href]="aboutData?.documentationURL">Manual</a>
      </div>
      <div class="link" *ngIf="isAdmin || isProvider">
        <a target="_blank" [href]="aboutData?.changelogsURL">Changelog</a>
      </div>
    </div>
  </div>

  <div class="by-seventh">
    <img src="../../../../assets/svg/by_seventh.svg">
  </div>

  <div class="close">
    <button type="button" nbButton ghost (click)="close()">
      <nb-icon icon="close-outline"></nb-icon>
    </button>
  </div>
</div>