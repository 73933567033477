<nb-layout center="false" windowMode>
  <nb-layout-header fixed class="header__background-color">
    <app-header *ngIf="currentUser && whiteLabel" [hideMenu]="hideMenu" [user]="currentUser" [whiteLabel]="whiteLabel"></app-header>
  </nb-layout-header>

  <nb-sidebar *ngIf="!hideMenu" class="menu-sidebar" tag="menu-sidebar" responsive right="false" (stateChange)="handleMenuState($event)">
    <nb-menu [items]="menu" autoCollapse="true" expanded="false" (click)="recalculateWidth()"></nb-menu>
  </nb-sidebar>

  <nb-layout-column>
    <nb-card>
      <router-outlet></router-outlet>
    </nb-card>
  </nb-layout-column>
</nb-layout>
