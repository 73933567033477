import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { SimultaneousSessionService } from 'src/app/shared/services/simultaneous-session.service';

@Component({
  selector: 'app-simultaneous-session-warning',
  templateUrl: './simultaneous-session-warning.component.html',
  styleUrls: ['./simultaneous-session-warning.component.scss']
})
export class SimultaneousSessionWarningComponent implements OnInit, OnDestroy {

  countdown: number = 15;
  message: {title: string, text: string};

  private readonly destroy$ = new Subject<void>();

  constructor(private simultaneousSessionService: SimultaneousSessionService) { }

  ngOnInit(): void {
    this.getMessage();
    this.startCountdown();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  startCountdown(): void {
    let interval = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        clearInterval(interval);
        this.simultaneousSessionService.hasSimultaneousSession.next(false);
      }
    }, 1000);
  }

  getMessage(): void {
    this.simultaneousSessionService.message.pipe(takeUntil(this.destroy$)).subscribe(message => {
      this.message = message;
    })
  }

  signOut(): void {
    this.simultaneousSessionService.hasSimultaneousSession.next(false);
  }
}
