import { Hierarchy } from "../models/hierarchy";
import { WhiteLabel } from "../models/white-label";

export class GetHierarchy {
  static execute(): Hierarchy {
    const whiteLabel: WhiteLabel = JSON.parse(sessionStorage.getItem('whiteLabel'));
    const { level_one, level_one_gender, level_one_plural, level_two, level_two_gender, level_two_plural } = whiteLabel;
    return {
      level_one,
      level_one_gender,
      level_one_plural,
      level_two,
      level_two_gender,
      level_two_plural,
    };
  }
}