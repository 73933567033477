import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';
import { NotFoundComponent } from './layouts/not-found/not-found.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { NewPasswordGuard } from './shared/guards/new-password.guard';
import { PendingLgpdGuard } from './shared/guards/pending-lgpd.guard';

const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: '/authentication/login',
        pathMatch: 'full'
      },
      {
        path: 'page',
        loadChildren: () => import('./modules/others/others-routes').then(m => m.OTHERS_ROUTES),
        canActivate: [AuthGuard]
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard-routes').then(m => m.DASHBOARD_ROUTES),
        canActivate: [AuthGuard, NewPasswordGuard, PendingLgpdGuard]
      },
      {
        path: 'resource',
        loadChildren: () => import('./modules/resource/resource-routes').then(m => m.RESOURCE_ROUTES),
        canActivate: [AuthGuard, NewPasswordGuard, PendingLgpdGuard]
      },
      {
        path: 'report',
        loadChildren: () => import('./modules/report/report-routes').then(m => m.REPORT_ROUTES),
        canActivate: [AuthGuard, NewPasswordGuard, PendingLgpdGuard]
      },
      {
        path: 'config',
        loadChildren: () => import('./modules/config/config-routes').then(m => m.CONFIG_ROUTES),
        canActivate: [AuthGuard, NewPasswordGuard, PendingLgpdGuard]
      },
      {
        path: 'terms',
        loadChildren: () => import('./modules/lgpd/lgpd-routes').then(m => m.LGPD_ROUTES),
        canActivate: [AuthGuard, NewPasswordGuard]
      },
      {
        path: 'notifications',
        loadChildren: () => import('./modules/notification/notification-routes').then(m => m.NOTIFICATION_ROUTES),
        canActivate: [AuthGuard, NewPasswordGuard, PendingLgpdGuard]
      }
    ]
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren: () => import('./modules/authentication/authentication-routes').then(m => m.AUTHENTICATION_ROUTES)
      },
      {
        path: '',
        loadChildren: () => import('./modules/message-pages/message-pages-routes').then(m => m.MESSAGE_PAGES_ROUTES),
        canActivate: [AuthGuard]
      },
      {
        path: '**',
        component: NotFoundComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
