<div class="simultaneous-session">
	<nb-card class="modal">
		<nb-card-header>
			<span class="title">{{ message.title }}</span>
		</nb-card-header>

		<nb-card-body>
			<p>{{ message.text }}</p>

				<div class="button-container">
					<button type="button" nbButton status="primary" data-id="button_submit" (click)="signOut()">
						Sair ({{ countdown }})
					</button>
				</div>
		</nb-card-body>
	</nb-card>
</div>