import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class HideMenuByRouteService {

  private hide = new BehaviorSubject<boolean>(true);

  constructor() { }

  getHideMenu(): Observable<boolean> {
    const user: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.setHideMenu(user.passwordTemporary || user.documentPending);
    return this.hide.asObservable();
  }

  setHideMenu(value: boolean): void {
    this.hide.next(value);
  }
}
