<div class="header-container">
  <div class="logo-container">
    <a *ngIf="!hideMenu" (click)="toggleSidebar()" href="#" class="sidebar-toggle" id="sidebar-toggle">
      <nb-icon icon="menu-outline"></nb-icon>
    </a>
    <div class="vertical-divider">
      <svg>
        <use xlink:href="../../../../assets/svg/sprite.svg#vertical-divider"></use>
      </svg>
    </div>
    <h2 class="logo">
      <img [src]="whiteLabel?.header_logo" [alt]="whiteLabel?.name">
    </h2>
  </div>
</div>

<div class="header-container" [nbSpinner]="loading">
  <nb-actions size="small">
    <nb-action class="control-item" [nbContextMenu]="themeSelectorMenu" nbContextMenuTag="theme-selector">
      <svg class="theme-selector" nbTooltip="Mudar tema de cores" [nbTooltipPlacement]="tooltipPlacement">
        <use xlink:href="../../../../assets/svg/sprite.svg#theme-selector"></use>
      </svg>
    </nb-action>

    <nb-action icon="bell-outline" class="notifications" status="primary" [badgeDot]="hasNotification" badgeStatus="info"
    (click)="navigateToNotification()">
    </nb-action>

    <nb-action class="user-action" [nbContextMenu]="userMenu" nbContextMenuTag="top-menu">
      <div class="user-action__photo">
        <nb-user *ngIf="!userPhotoUrl" [name]="user?.name" [onlyPicture]="true"></nb-user>
        <div class="user-photo" *ngIf="userPhotoUrl">
          <img [src]="userPhotoUrl">
        </div>
      </div>
      <p *ngIf="!userPictureOnly">{{ user?.name }}</p>
    </nb-action>
  </nb-actions>
</div>
