import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbDialogRef, NbThemeService } from '@nebular/theme';
import { Subject, takeUntil } from 'rxjs';
import { User } from '../../models/user';
import { Dimensions, ImageCroppedEvent, LoadedImage, ImageCropperModule } from 'ngx-image-cropper';
import { UserService } from '../../services/user.service';
import { ToastService } from 'ngx-french-toast';
import { CustomNebularThemeModule } from 'src/app/shared/customs/custom-nebular-theme.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-change-user-photo',
  templateUrl: './change-user-photo.component.html',
  styleUrls: ['./change-user-photo.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CustomNebularThemeModule,
    ImageCropperModule,
    FormsModule
  ]
})
export class ChangeUserPhotoComponent implements OnInit, OnDestroy {
  loading: boolean;
  theme: string;
  user: User;
  showCropper: boolean;
  acceptedFormats: string = 'image/png, image/jpeg, image/jpg';
  imageChangedEvent: any;
  file: any;
  fileToUpload: any = null;
  croppedImage: any = '';
  currentImage: string | ArrayBuffer = null;
  disableButton: boolean = false;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private dialogRef: NbDialogRef<ChangeUserPhotoComponent>,
    private nbThemeService: NbThemeService,
    private userService: UserService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.getCurrentTheme();
    if (this.user?.photoUrl) {
      this.disableButton = true;
      this.currentImage = this.user.photoUrl;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close(shouldReload: boolean = false): void {
    this.dialogRef.close(shouldReload);
  }

  getCurrentTheme(): void {
    this.nbThemeService.onThemeChange().pipe(takeUntil(this.destroy$)).subscribe({
      next: (theme) => {
        this.theme = theme.name;
      }
    })
  }

  onCropperReady(event: Dimensions) {
    this.loading = false;
  }

  fileChangeEvent(event): void {
    this.disableButton = false;
    const image = event.target.files[0];
    if (!image.type.includes('image/png') && !image.type.includes('image/jpg') && !image.type.includes('image/jpeg')) {
      this.toastService.info({
        title: 'Foto do Perfil',
        content: 'O formato do arquivo não é válido. Extensões permitidas: JPG, JPEG e PNG.',
        duration: 7000
      });
      this.file = null;
      return;
    }
    if (image.size > 3145728) {
      this.toastService.info({
        title: 'Foto do Perfil',
        content: 'Esta foto é maior que o limite de 3mb. Por favor, selecione outra.',
        duration: 7000
      })
      this.file = null;
      return;
    }
    this.loading = true;
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }

  imageLoaded(event: LoadedImage): void {
    this.showCropper = true;
  }

  cancelCrop(): void {
    this.croppedImage = null;
    this.fileToUpload = null;
    this.showCropper = false;
    this.file = null;
  }

  addCroppedImage(): void {
    this.currentImage = this.croppedImage;
    this.fileToUpload = this.croppedImage;
    this.showCropper = false;
    this.file = null;
  }

  deleteImage() {
    this.file = null;
    this.fileToUpload = null;
    this.currentImage = null;
    this.disableButton = false;
  }

  save(): void {
    this.loading = true;
    this.userService.changePhoto(this.user._id, this.fileToUpload).pipe(takeUntil(this.destroy$)).subscribe({
      next: (res) => {
        this.user.photoUrl = res.photoUrl;
        sessionStorage.setItem('currentUser', JSON.stringify(this.user));
        this.loading = false;
        this.toastService.success({
          title: 'Foto do Perfil',
          content: 'Foto alterada com sucesso.'
        });
        this.close(true);
      },
      error: (err) => {
        this.loading = false;
        this.toastService.danger({
          title: 'Foto do Perfil',
          content: err?.error?.message ?? 'Não foi possível alterar a foto.'
        })
      }
    })
  }
}