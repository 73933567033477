import { NbThemeService } from '@nebular/theme';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { SignOutService } from 'src/app/modules/authentication/services/sign-out.service';

@Injectable()
export class TokenStatusInterceptor implements HttpInterceptor {

  constructor(
    private signoutService: SignOutService,
    private themeService: NbThemeService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err?.error === 'Token expirado.') {
          setTimeout(() => {
            this.themeService.changeTheme('default');
            this.signoutService.signOut(true);
          }, 1000);
        }
        return throwError(() => err)
      })
    );
  }
}
